import axios from 'axios'
import errorHandler from './error-handler'

const ROTA = 'https://app.bembomsalgados.com.br/teleentrega/pedidos'

export default {
  async cadastrar(pedidoDto) {
    try {
      await axios.post(`${ROTA}`, pedidoDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async atualizar(pedidoDto) {
    try {
      await axios.put(`${ROTA}`, pedidoDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaPorId(pedidoId) {
    try {
      const { data } = await axios.get(`${ROTA}/filtrar-id/${pedidoId}`)
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaPedidosPorStatus(dataInicial, dataFinal, status) {
    try {
      const { data } = await axios.get(
        `${ROTA}/filtrar-por-status?dataInicial=${dataInicial}&dataFinal=${dataFinal}&status=${status}`
      )
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaPorPeriodoSimplificado(dataInicial, dataFinal) {
    try {
      const { data } = await axios.get(
        `${ROTA}/filtrar-periodo/simplificado?dataInicial=${dataInicial}&dataFinal=${dataFinal}`
      )
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async concluirPedido(pedidoId) {
    try {
      await axios.post(`${ROTA}/concluir/${pedidoId}`)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async cancelarPedido(cancelarDto) {
    try {
      await axios.put(`${ROTA}/cancelar`, cancelarDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async adicionarEntregador(entregadorDto) {
    try {
      await axios.put(`${ROTA}/adicionar-entregador`, entregadorDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
}
