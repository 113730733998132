<template>
  <v-dialog persistent v-model="mostrar" width="500">
    <l-tela titulo="Cancelar pedido">
      <l-form :salvar="salvar">
        <i-texto-longo
          id="motivoCancelamento"
          label="Motivo do cancelamento"
          v-model="form.motivo"
          :validations="{ required: true, min: 10 }"
        />
        <template v-slot:botoes>
          <v-btn @click="() => sair(false)">Sair</v-btn>
          <v-btn color="primary" type="submit">Confirmar</v-btn>
        </template>
      </l-form>
    </l-tela>
  </v-dialog>
</template>

<script>
import pedidoService from '@/services/pedido.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      mostrar: false,
      form: {
        motivo: '',
      },
      pedidoId: null,
      resolve: null,
    }
  },
  methods: {
    async abrir(pedidoId) {
      this.pedidoId = pedidoId
      this.mostrar = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    async salvar() {
      this.mostrarLoading()
      try {
        const cancelamentoDto = {
          pedidoId: this.pedidoId,
          motivo: this.form.motivo,
        }

        await pedidoService.cancelarPedido(cancelamentoDto)
        this.sair(true)
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    sair(salvouRegistro) {
      this.limpar()
      this.mostrar = false
      this.resolve(salvouRegistro)
    },
    limpar() {
      this.pedidoId = null
      this.form.motivo = ''
    },
  },
}
</script>
