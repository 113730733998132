var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Pedidos em aberto: "+_vm._s(_vm._f("ddmmyyyy")(_vm.dataFiltro)))]),_c('v-card-text',{staticClass:"painel-pedidos"},_vm._l((_vm.pedidos),function(pedido){return _c('v-card',{key:pedido._id,staticClass:"card"},[_c('v-card-title',[_vm._v("Pedido: "+_vm._s(pedido.codigo))]),_c('v-card-text',{staticClass:"painel-pedidos-dados"},[_c('span',[_vm._v("Cliente: "+_vm._s(pedido.nome))]),_c('span',[_vm._v("Hora de entrega: "+_vm._s(_vm._f("hhmm")(pedido.dataEntrega)))]),(pedido.retirarBalcao)?_c('span',[_vm._v("Retirar no balcão: Sim")]):_vm._e(),(pedido.endereco)?_c('span',[_vm._v("Endereço: "+_vm._s(pedido.endereco)+" - "+_vm._s(pedido.numero))]):_vm._e(),(pedido.cidade)?_c('span',[_vm._v("Cidade: "+_vm._s(pedido.cidade))]):_vm._e(),(pedido.bairro)?_c('span',[_vm._v("Bairro: "+_vm._s(pedido.bairro))]):_vm._e(),(pedido.formaPagamento)?_c('span',[_vm._v("Forma de pgto: "+_vm._s(_vm.retornaFormaPgt(pedido.formaPagamento)))]):_vm._e(),(pedido.retirarBalcao)?_c('span',[_vm._v("[ Balcão ]")]):_vm._e(),(pedido.entregador)?_c('span',[_vm._v("Entregador: "+_vm._s(pedido.entregador.nome))]):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){return _vm.abrirModalCadastro(pedido._id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar pedido")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){return _vm.imprimirPedido(pedido)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir")])]),(!pedido.retirarBalcao)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":pedido.entregador ? 'green' : '',"text":""},on:{"click":function($event){return _vm.adicionarEntregador(pedido)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-moped")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar entregador")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){return _vm.concluirPedido(pedido)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Concluir pedido")])])],1)],1)}),1),(_vm.mostrarModalPedido)?_c('CadastroPedido',{attrs:{"mostrar":_vm.mostrarModalPedido,"idPedidoSelecionado":_vm.idPedidoSelecionado},on:{"fechar":_vm.fecharModalCadastro}}):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","large":"","fixed":"","bottom":"","right":""},on:{"click":function () { return _vm.abrirModalCadastro(); },"fechar":_vm.fecharModalCadastro}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Abrir novo pedido")])]),_c('m-confirmacao',{ref:"modalConfirmarImpressao",attrs:{"titulo":"Confirmação","mensagem":"Deseja mesmo imprimir esse pedido?"}}),_c('m-confirmacao',{ref:"modalConcluirPedido",attrs:{"titulo":"Confirmação","mensagem":"Deseja mesmo concluir esse pedido?"}}),_c('Entregador',{ref:"modalAdicionarEntregador"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }