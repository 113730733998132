<template>
  <v-dialog
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
    persistent
    v-model="mostrar"
  >
    <v-card>
      <v-card-title>{{
        this.formDadosEntrega.codigo
          ? `Pedido: ${this.formDadosEntrega.codigo}`
          : `Cadastrar novo pedido`
      }}</v-card-title>
      <v-card-text>
        <div class="cadastro-pedido">
          <div>
            <l-form
              :cssColunas="4"
              :salvar="adicionarProduto"
              class="form-pedido-produtos"
            >
              <i-produto
                id="produto"
                label="Produto"
                v-model="formProduto.produto"
                :validations="{ required: true }"
                class="produto"
              />

              <i-basico
                id="quantidade"
                label="Quantidade"
                type="number"
                v-model="formProduto.quantidade"
                :validations="{ required: true }"
                class="quantidade"
              />

              <i-basico
                id="observacao"
                label="Observação"
                v-model="formProduto.observacao"
                class="observacao"
                maxlength="45"
              />

              <v-btn
                :disabled="!habilitaBotoesAlteracao()"
                class="botao-adicionar"
                color="primary"
                type="submit"
                >Adicionar</v-btn
              >
            </l-form>
            <v-data-table
              :key="editandoProdutoKey"
              :headers="tabelaProdutos.colunas"
              :items="tabelaProdutos.registros"
              :disable-items-per-page="true"
              @click:row="clickRow"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
            >
              <template v-slot:[`item.preco`]="{ item }">
                {{ item.preco | currency }}
              </template>

              <template v-slot:[`item.remover`]="{ index }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!habilitaBotoesAlteracao()"
                      color="warning"
                      @click="removerProduto(index)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </template>
                  <span>Remover produto</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
          <v-divider class="cadastro-pedido__divisor" vertical></v-divider>
          <l-form
            :cssColunas="4"
            class="form-dados-entrega"
            :salvar="salvarPedido"
          >
            <i-contato
              :disabled="desabilitaCampoContatoNome()"
              id="contato"
              label="Contato"
              v-model="formDadosEntrega.contato"
              :validations="{ required: true, validaLenCel: true }"
              class="duas-colunas-ini"
              :blur="buscaClienteContato"
            />

            <i-basico
              :disabled="desabilitaCampoContatoNome()"
              id="nome"
              label="Nome"
              :validations="{ required: true, min: 3 }"
              v-model="formDadosEntrega.nome"
              class="duas-colunas-fim"
            />

            <i-basico
              id="endereco"
              label="Endereço"
              v-model="formDadosEntrega.endereco"
              class="tres-colunas-ini"
            />

            <i-basico
              id="numero"
              label="Número"
              type="number"
              v-model="formDadosEntrega.numero"
              class="uma-coluna-fim"
            />
            
            <i-basico
              id="endereco"
              label="Obs. endereço"
              v-model="formDadosEntrega.observacaoEntrega"
              class="linha-toda"
            />

            <i-cidade
              id="cidade"
              label="Cidade"
              v-model="formDadosEntrega.cidade"
              class="duas-colunas-ini"
            />

            <i-basico
              id="bairro"
              label="Bairro"
              v-model="formDadosEntrega.bairro"
              class="duas-colunas-fim"
            />

            <i-data
              id="dataEntrega"
              label="Data de entrega"
              class="duas-colunas-ini"
              v-model="formDadosEntrega.dataEntrega"
              :validations="{ required: true }"
            />

            <i-hora
              id="horaEntrega"
              label="Hora de entrega"
              class="duas-colunas-fim"
              v-model="formDadosEntrega.horaEntrega"
              :validations="{ required: true }"
            />

            <i-forma-pagamento
              id="formaPagamento"
              label="Forma pagamento"
              class="duas-colunas-ini"
              v-model="formDadosEntrega.formaPagamento"
              :validations="{ required: true }"
            />

            <i-dinheiro
              id="precoProduto"
              label="Taxa de entrega"
              v-model="formDadosEntrega.entrega"
              class="duas-colunas-fim"
            />

            <i-dinheiro
              v-if="mestre"
              id="precoDesconto"
              label="Desconto"
              v-model="formDadosEntrega.desconto"
              class="duas-colunas-ini"
            />

            <i-check
              v-if="!formDadosEntrega.entregador"
              id="retirarRalcao"
              label="Retirar no balcão?"
              v-model="formDadosEntrega.retirarBalcao"
              class="duas-colunas-ini"
            />

            <i-texto-longo
              id="observacao"
              label="Observação"
              :rows="4"
              v-model="formDadosEntrega.observacao"
              class="linha-toda"
            />

            <button
              :disabled="!habilitaBotoesAlteracao()"
              ref="botaoSalvarDadosEntrega"
              type="submit"
              class="form-dados-entrega-esconder"
            >
              Salvar
            </button>
            
          </l-form>
        </div>
      </v-card-text>
      <v-card-actions>
        <span class="pedido-total"
          >Total:
          <span class="pedido-total__valor">{{
            getTotalPedido() | currency
          }}</span></span
        >
        <v-spacer />
        <v-btn
          @click="imprimirPedido()"
          text
          v-bind="attrs"
          v-on="on"
          ><v-icon>mdi-printer</v-icon></v-btn
        >
        <v-tooltip top v-if="this.formDadosEntrega._id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              :disabled="!habilitaBotoesAlteracao()"
              @click="cancelarPedido"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <span>Cancelar pedido</span>
        </v-tooltip>
        <v-btn @click="sair">Sair</v-btn>
        <v-btn
          :disabled="!habilitaBotoesAlteracao()"
          color="primary"
          @click="() => this.$refs.botaoSalvarDadosEntrega.click()"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>

    <m-confirmacao
      ref="modalConfirmarImpressao"
      titulo="Confirmação"
      mensagem="Deseja mesmo imprimir esse pedido?"
    />

    <m-confirmacao
      ref="modalRemoverProduto"
      titulo="Confirmação"
      mensagem="Deseja mesmo remover esse produto do pedido?"
    />

    <m-confirmacao
      ref="modalSalvarPedido"
      titulo="Confirmação"
      mensagem="Deseja mesmo salvar esse pedido?"
    />

    <m-confirmacao
      ref="modalSairPedido"
      titulo="Confirmação"
      mensagem="Deseja mesmo sair do cadastro de pedido?"
    />

    <m-confirmacao
      ref="modalCancelarPedido"
      titulo="Atenção"
      mensagem="Deseja mesmo cancelar o pedido?"
    />

    <MotivoCancelamento ref="modalMotivoCancelamento" />
  </v-dialog>
</template>

<script>
import * as dataUtils from '@/utils/data.utils'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'
import pedidoService from '@/services/pedido.service'
import clienteService from '@/services/cliente.service'
import MotivoCancelamento from './MotivoCancelamento.vue'
import store from '@/store'

import localstorage from '@/utils/localstorage.utils'
import { gerarPDF } from "../Painel/imprimePedido";

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  components: {
    MotivoCancelamento,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    idPedidoSelecionado: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mestre: false,
      editandoProduto: false,
      editandoProdutoKey: 0,
      on: null,
      attrs: null,
      formProduto: {
        produto: null,
        quantidade: null,
        observacao: null,
      },
      formDadosEntrega: {
        contato: '',
        nome: '',
        endereco: '',
        observacaoEntrega: '',
        numero: '',
        cidade: '',
        bairro: '',
        dataEntrega: null,
        horaEntrega: null,
        formaPagamento: '',
        entrega: '',
        desconto: '',
        retirarBalcao: false,
        observacao: '',
      },
      tabelaProdutos: {
        colunas: [
          {
            text: 'Nome do produto',
            value: 'nome',
            width: 400,
          },
          { text: 'Quantidade', value: 'quantidade' },
          { text: 'Observação do item', value: 'observacao' },
          { text: 'Preço', value: 'preco' },
          { text: '', value: 'remover' },
        ],
        registros: [],
      },
    }
  },
  mounted() {
    this.mestre = store.getters['rotaMestre']
    this.idPedidoSelecionado && this.carregar(this.idPedidoSelecionado)
  },
  methods: {
    habilitaBotoesAlteracao() {
      if (
        !this.formDadosEntrega.status ||
        this.formDadosEntrega.status === 'ABERTO'
      ) {
        return true
      }
      return false
    },
    async sair(mostrarConfirmacao = true) {
      if (mostrarConfirmacao) {
        const deveSair = await this.$refs.modalSairPedido.pop()
        if (!deveSair) {
          return
        }
      }

      this.$emit('fechar')
    },
    async carregar(pedidoId) {
      this.mostrarLoading()
      try {
        const pedido = await pedidoService.buscaPorId(pedidoId)
        const { produtos, ...demaisCampos } = pedido
        this.tabelaProdutos.registros = produtos.map((produto, index) =>  { 
          return {index, ...produto} 
        })
        
        console.log(this.tabelaProdutos.registros)
        const dataEntrega = dataUtils.paraDataYYYYMMDD(pedido.dataEntrega)
        const horaEntrega = dataUtils.paraHora(pedido.dataEntrega)

        this.formDadosEntrega = { ...demaisCampos, dataEntrega, horaEntrega }
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    adicionarProduto() {
      const {
        produto: { _id, nome, preco },
        quantidade, observacao
      } = this.formProduto
      console.log(this.formProduto.index)
      if(this.formProduto.index >= 0) {
        this.tabelaProdutos.registros[this.formProduto.index] = {
           _id,
          nome,
          preco,
          quantidade,
          observacao
        }
      } else {
        this.tabelaProdutos.registros.push({
          _id,
          nome,
          preco,
          quantidade,
          observacao
        })
      }
      
      

      this.tabelaProdutos.registros = this.tabelaProdutos.registros.map((produto, index) =>  { 
        return {index, ...produto} 
      })


      this.formProduto = {
        produto: '',
        quantidade: '',
        observacao: '',
        index: -1,
      }
      
    },
    async removerProduto(indice) {
      console.log(indice)
      if (indice >= 0) {
        const podeRemover = await this.$refs.modalRemoverProduto.pop()
        if (podeRemover) {
          this.tabelaProdutos.registros.splice(indice, 1)
        }
      }
    },
    async clickRow(produto) {
      this.formProduto = {
        produto: produto,
        quantidade: produto.quantidade,
        observacao: produto.observacao,
        index: produto.index
      }
    },
    async salvarPedido() {
      const deveSalvar = await this.$refs.modalSalvarPedido.pop()
      if (!deveSalvar) {
        return
      }

      this.mostrarLoading()
      try {
        const pedidoDto = {
          ...this.formDadosEntrega,
          dataEntrega: `${this.formDadosEntrega.dataEntrega}T${this.formDadosEntrega.horaEntrega}:00`,
          produtos: this.tabelaProdutos.registros,
        }
        console.log(pedidoDto)
        if (pedidoDto._id) {
          await pedidoService.atualizar(pedidoDto)
        } else {
          await pedidoService.cadastrar(pedidoDto)
        }

        this.alertaDadosSalvos()
        this.sair(false)
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    async buscaClienteContato() {
      if (this.formDadosEntrega.contato === '') {
        return
      }
      const dadosCliente = await clienteService.buscaPorContato(
        this.formDadosEntrega.contato
      )

      if (dadosCliente) {
        this.formDadosEntrega.nome = dadosCliente.nome
        this.formDadosEntrega.endereco = dadosCliente.endereco
        this.formDadosEntrega.observacaoEntrega = dadosCliente.observacaoEntrega
        this.formDadosEntrega.numero = dadosCliente.numero
        this.formDadosEntrega.cidade = dadosCliente.cidade
        this.formDadosEntrega.bairro = dadosCliente.bairro
      }
    },
    getTotalPedido() {
      let soma = 0
      this.tabelaProdutos?.registros?.forEach((produto) => {
        soma += produto.preco * produto.quantidade
      })
      if (this.formDadosEntrega.entrega && this.formDadosEntrega.entrega > 0){
        soma += parseFloat(this.formDadosEntrega.entrega)
      }
      if (this.formDadosEntrega.desconto && this.formDadosEntrega.desconto > 0){
        soma -= parseFloat(this.formDadosEntrega.desconto)
      }
      return soma
    },
    async cancelarPedido() {
      const podeCancelar = await this.$refs.modalCancelarPedido.pop()
      if (podeCancelar) {
        const cancelou = await this.$refs.modalMotivoCancelamento.abrir(
          this.formDadosEntrega._id
        )

        if (cancelou) {
          this.sair(false)
        }
      }
    },
    desabilitaCampoContatoNome() {
      return (
        this.formDadosEntrega._id !== null &&
        this.formDadosEntrega._id !== undefined
      )
    },
    async imprimirPedido() {
      const pedidoDto = {
          ...this.formDadosEntrega,
          dataEntrega: `${this.formDadosEntrega.dataEntrega}T${this.formDadosEntrega.horaEntrega}:00`,
          produtos: this.tabelaProdutos.registros,
        }

      const deveConcluir = await this.$refs.modalConfirmarImpressao.pop()
      if (deveConcluir) {
        await gerarPDF(this, localstorage.getUserObj(), pedidoDto)
        // console.log('imprime :)')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cadastro-pedido {
  display: flex;
  flex-direction: column;

  @media (min-width: $custom-extra-large-size) {
    flex-direction: row;
  }

  &__divisor {
    display: none;
    @media (min-width: $custom-extra-large-size) {
      display: block;
      margin: 0 1rem;
    }
  }

  &__produtos {
    margin-bottom: 0.4rem;
    @media (min-width: $custom-extra-large-size) {
      margin-bottom: 0;
    }
  }
}

.form-pedido-produtos {
  & .produto {
    grid-column: 1 / 4;
  }

  & .quantidade {
    grid-column: 4 / 5;
  }

  & .observacao {
    grid-column: 1 / 4;
  }

  & .botao-adicionar {
    width: 100%;
    margin-top: 1rem;
  }
}

.form-dados-entrega {
  & .duas-colunas-ini {
    grid-column: 1 / 3;
  }

  & .tres-colunas-ini {
    grid-column: 1 / 4;
  }

  & .uma-coluna-fim {
    grid-column: 4 / -1;
  }

  & .duas-colunas-fim {
    grid-column: 3 / -1;
  }

  & .linha-toda {
    grid-column: 1 / -1;
  }

  &-esconder {
    display: none;
  }
}

.pedido-total {
  &__valor {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
</style>
