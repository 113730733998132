<template>
  <l-tela class="agenda-pedidos">
    <div v-if="!mostrarModalPedido">
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setDataCalendarioDiaAtual"
          >
            Ver mês atual
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="mesAnterior">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="proximoMes">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :event-color="getCorPedidoCalendario"
          :type="type"
          :events="pedidos"
          :event-overlap-mode="mode"
          :interval-height="240"
          @click:event="mostrarPedido"
          @click:more="mudarVisualizacaoParaDia"
          @click:date="mudarVisualizacaoParaDia"
          @change="alteraPeriodo"
        />
      </v-sheet>
    </div>
    <CadastroPedido
      v-if="mostrarModalPedido"
      :mostrar="mostrarModalPedido"
      :idPedidoSelecionado="idPedidoSelecionado"
      @fechar="fecharModalCadastro"
    />
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          large
          fixed
          bottom
          right
          v-bind="attrs"
          v-on="on"
          @click="() => abrirModalCadastro()"
          @fechar="fecharModalCadastro"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Abrir novo pedido</span>
    </v-tooltip>
  </l-tela>
</template>

<script>
import CadastroPedido from '../Cadastro/Pedido.vue'
import pedidoService from '@/services/pedido.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'
import * as dataUtil from '@/utils/data.utils'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  components: {
    CadastroPedido,
  },
  data() {
    return {
      mode: 'column',
      idPedidoSelecionado: '',
      mostrarModalPedido: false,
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      pedidos: [],
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
    }
  },
  methods: {
    abrirModalCadastro(pedidoId = '') {
      this.idPedidoSelecionado = pedidoId
      this.mostrarModalPedido = true
    },
    fecharModalCadastro() {
      this.idPedidoSelecionado = null
      this.mostrarModalPedido = false
    },
    mudarVisualizacaoParaDia({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getCorPedidoCalendario(event) {
      return event.color
    },
    setDataCalendarioDiaAtual() {
      this.focus = ''
    },
    mesAnterior() {
      this.$refs.calendar.prev()
    },
    proximoMes() {
      this.$refs.calendar.next()
    },
    mostrarPedido({ nativeEvent, event }) {
      if (event && event._id) {
        this.abrirModalCadastro(event._id)
      }

      nativeEvent.stopPropagation()
    },
    async alteraPeriodo({ start, end }) {
      this.pedidos = []
      this.mostrarLoading()
      try {
        const dataFim = dataUtil.adicionaDia(end.date)

        const pedidos = await pedidoService.buscaPorPeriodoSimplificado(
          start.date,
          dataFim
        )

        this.pedidos = pedidos.sort((p, p2) => {return p.dataEntrega > p2.dataEntrega}).map((pedido, index) => {
          const { _id, dataEntrega, nome, codigo, status, retirarBalcao } = pedido
          console.log(pedido)
          
          const inicio = dataUtil.adicionaHoras(dataEntrega, 3)
          const fim = dataUtil.adicionaMinutos(inicio, 1)
          console.log(`[#${codigo}${status=='CANCELADO'?' - CANCELADO':''}] ${nome} ${index}`,inicio, fim)
          return {
            _id,
            key: index,
            name: `[#${codigo}${status=='CANCELADO'?' - CANCELADO':''} ${retirarBalcao?' - BALCÃO':''}] ${nome}`,
            start: inicio,
            end: fim,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: true,
          }
        })
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>

<style lang="scss" scoped>
.agenda-pedidos {
  @media (min-width: $custom-medium-size) {
    min-width: $custom-small-size;
  }

  @media (min-width: $custom-large-size) {
    min-width: $custom-medium-size;
  }

  @media (min-width: $custom-extra-large-size) {
    min-width: $custom-large-size;
  }
}
</style>
