<template>
  <v-card elevation="0">
    <v-card-title>Pedidos em aberto: {{ dataFiltro | ddmmyyyy }}</v-card-title>
    <v-card-text class="painel-pedidos">
      <v-card class="card" v-for="pedido in pedidos" :key="pedido._id">
        <v-card-title>Pedido: {{ pedido.codigo }}</v-card-title>
        <v-card-text class="painel-pedidos-dados">
          <span>Cliente: {{ pedido.nome }}</span>
          <span>Hora de entrega: {{ pedido.dataEntrega | hhmm }}</span>
          <span v-if="pedido.retirarBalcao">Retirar no balcão: Sim</span>
          <span v-if="pedido.endereco">Endereço: {{ pedido.endereco }} - {{ pedido.numero }}</span>
          <span v-if="pedido.cidade">Cidade: {{ pedido.cidade }}</span>
          <span v-if="pedido.bairro">Bairro: {{ pedido.bairro }}</span>
          <span v-if="pedido.formaPagamento">Forma de pgto: {{ retornaFormaPgt(pedido.formaPagamento) }}</span>
          <span v-if="pedido.retirarBalcao">[ Balcão ]</span>
          <span v-if="pedido.entregador"
            >Entregador: {{ pedido.entregador.nome }}</span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="abrirModalCadastro(pedido._id)"
                text
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-clipboard-edit-outline</v-icon></v-btn
              >
            </template>
            <span>Editar pedido</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="imprimirPedido(pedido)"
                text
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-printer</v-icon></v-btn
              >
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!pedido.retirarBalcao">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="pedido.entregador ? 'green' : ''"
                @click="adicionarEntregador(pedido)"
                text
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-moped</v-icon></v-btn
              >
            </template>
            <span>Adicionar entregador</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="concluirPedido(pedido)"
                text
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-check-outline</v-icon></v-btn
              >
            </template>
            <span>Concluir pedido</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-card-text>

    <CadastroPedido
      v-if="mostrarModalPedido"
      :mostrar="mostrarModalPedido"
      :idPedidoSelecionado="idPedidoSelecionado"
      @fechar="fecharModalCadastro"
    />

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          large
          fixed
          bottom
          right
          v-bind="attrs"
          v-on="on"
          @click="() => abrirModalCadastro()"
          @fechar="fecharModalCadastro"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Abrir novo pedido</span>
    </v-tooltip>

    <m-confirmacao
      ref="modalConfirmarImpressao"
      titulo="Confirmação"
      mensagem="Deseja mesmo imprimir esse pedido?"
    />

    <m-confirmacao
      ref="modalConcluirPedido"
      titulo="Confirmação"
      mensagem="Deseja mesmo concluir esse pedido?"
    />

    <Entregador ref="modalAdicionarEntregador" />
  </v-card>
</template>

<script>
import Entregador from './Entregador.vue'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'
import pedidoService from '@/services/pedido.service'
import CadastroPedido from '../Cadastro/Pedido.vue'
import * as dataUtil from '@/utils/data.utils'
import localstorage from '@/utils/localstorage.utils'

import { gerarPDF } from "./imprimePedido";

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  components: {
    Entregador,
    CadastroPedido,
  },
  data() {
    return {
      dataFiltro: null,
      pedidos: [],
      idPedidoSelecionado: '',
      mostrarModalPedido: false,
      formasPagamento: [
        {
          text: 'Dinheiro',
          value: 'dinheiro',
        },
        {
          text: 'PIX',
          value: 'pix',
        },
        {
          text: 'Boleto',
          value: 'boleto',
        },
        {
          text: 'Cartão débito',
          value: 'cartao_debito',
        },
        {
          text: 'Cartão crédito',
          value: 'cartao_credito',
        },
        {
          text: 'Vale alimentação',
          value: 'vale_alimentacao',
        },
        {
          text: 'Vale refeição',
          value: 'vale_refeicao',
        },
        {
          text: 'Vale refeição',
          value: 'Vale refeição',
        },
      ],
    }
  },
  mounted() {
    this.dataFiltro = new Date()
    this.buscar()
  },
  methods: {
    async buscar() {
      this.pedidos = []
      this.mostrarLoading()
      try {
        const inicio = dataUtil.paraDataYYYYMMDD(this.dataFiltro)
        const fim = dataUtil.adicionaDia(this.dataFiltro)

        const pedidos = await pedidoService.buscaPedidosPorStatus(
          inicio,
          fim,
          'ABERTO'
        )

        this.pedidos = pedidos
      } catch (error) {
        console.log(error)
      } finally {
        this.fecharLoading()
      }
    },
    retornaFormaPgt(formaPagamento) {
      return this.formasPagamento.filter(f => { return f.value == formaPagamento})[0].text
    },
    async concluirPedido(pedido) {
      const deveConcluir = await this.$refs.modalConcluirPedido.pop()
      if (deveConcluir && pedido._id) {
        this.mostrarLoading()

        try {
          await pedidoService.concluirPedido(pedido._id)
          this.buscar()
          this.alertaDadosSalvos()
        } catch (error) {
          this.mostraNotificacao(error)
        } finally {
          this.fecharLoading()
        }
      }
    },
    async imprimirPedido(pedido) {
      const deveConcluir = await this.$refs.modalConfirmarImpressao.pop()
      if (deveConcluir) {
        await gerarPDF(this, localstorage.getUserObj(), pedido)
        // console.log('imprime :)')
      }
    },
    async adicionarEntregador(pedido) {
      const salvouEntregador = await this.$refs.modalAdicionarEntregador.abrir(
        pedido._id
      )

      if (salvouEntregador) {
        this.buscar()
      }
    },
    abrirModalCadastro(pedidoId = '') {
      this.idPedidoSelecionado = pedidoId
      this.mostrarModalPedido = true
    },
    fecharModalCadastro() {
      this.idPedidoSelecionado = null
      this.mostrarModalPedido = false
      this.buscar()
    },
  },
}
</script>

<style lang="scss" scoped>
.tela-painel-pedidos {
  padding: 1rem;
}

.painel-pedidos {
  & .card {
    margin-bottom: 1rem;
  }

  @media (min-width: $custom-medium-size) {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  @media (min-width: $custom-large-size) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $custom-extra-large-size) {
    grid-template-columns: repeat(4, 1fr);
  }

  &-dados {
    display: flex;
    flex-direction: column;
  }
}
</style>
