import jsPDF from "jspdf";
import "jspdf-autotable";


import { paraDataDDMMYYYY, paraHora } from '@/utils/data.utils'

// import {
//   retornaDataFormatadaDDMMYYYY,
//   retornaISOParaStringComMascara,
// } from "@/common/helpers/DataHelper";

var doc = null
var baseTop = 0

// function setBaseTop(increment=0) {
  
// }

export const gerarPDF = async (_this, empresa, pedido) => {
    let countTamanho = 135
    pedido.produtos.forEach(p => {
        countTamanho += 28
    });
    if(pedido.observacao && pedido.observacao != ''){
        countTamanho += 35
    }
    if(pedido.desconto && pedido.desconto > 0){
        countTamanho += 35
    }
    if(pedido.retirarBalcao){
        countTamanho += 35
    }

    if(pedido.entrega && pedido.entrega > 0){
        countTamanho += 25
    }
    

    doc = new jsPDF('p', 'mm', [118, countTamanho]);
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    baseTop = 8;
    let baseEsq = 2;

    // doc.setTextColor(100);
    // doc.setDrawColor(100);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(
      `Data: ${paraDataDDMMYYYY(new Date())}`,
      baseEsq,
      baseTop,
    );

    doc.text(
      `[ PEDIDO - ${pedido.codigo} ]`,
      pageWidth - 5,
      baseTop,
      "right",
    );
    
    pulaLinha()
    pulaLinha()
    baseTop -= 5
    doc.setFontSize(22);
    doc.text( `${empresa.empresaNome}`,
        baseEsq,
        baseTop,
    );

    pulaLinha()
    doc.line(baseEsq , baseTop-3, pageWidth - 8, baseTop-3);
    
    pulaLinha()
    doc.setFont("helvetica", "normal");
    doc.setFontSize(18);
    doc.text( `Entregar: ${paraDataDDMMYYYY(pedido.dataEntrega)} às ${paraHora(pedido.dataEntrega)}`,
        baseEsq,
        baseTop,
    );
    pulaLinha()
    doc.text( `Obs.: ${pedido.observacaoEntrega||''}`,
        baseEsq,
        baseTop,
    );

    pulaLinha()
    pulaLinha()
    doc.setFontSize(22);
    doc.setFont("helvetica", "bold");
    doc.text( `Cliente: ${pedido.nome}`,
        baseEsq,
        baseTop,
    );

    pulaLinha()
    doc.setFontSize(18);
    doc.setFont("helvetica", "normal");
    doc.text( `Telefone: ${pedido.contato}`,
        baseEsq,
        baseTop,
    );

    pulaLinha()
    pulaLinha()
    doc.setFontSize(22);
    doc.text( `Endereço:`,
        baseEsq,
        baseTop,
    );
    pulaLinha()
    doc.setFont("helvetica", "normal");
    doc.setFontSize(18);
    doc.text( `${pedido.endereco}, ${pedido.numero}`,
        baseEsq,
        baseTop,
    );
    pulaLinha()
    doc.text( `${pedido.bairro} / ${pedido.cidade}`,
        baseEsq,
        baseTop,
    );

    let columnsItens = [
        { title: "Qtd.", dataKey: "quantidade" },
        { title: "Produto", dataKey: "nome" },
        { title: "Preço", dataKey: "preco" }
    ];
    pulaLinha()
    pulaLinha()
    console.log(baseTop)
    
    let arrPedidos = pedido.produtos.map(item => {
        return {
                    quantidade: `x${item.quantidade}`,
                    nome: item.nome,
                    preco: `R$ ${item.preco * item.quantidade}`,
                    observacao: item.observacao
                }
            })
    
    console.log(arrPedidos.length, arrPedidos.length * 28)
    doc.setFont("helvetica", "normal");
    doc.setFontSize(18);
    arrPedidos.forEach(p => {
        doc.setFontSize(18);
        doc.text( `${p.quantidade} | ${p.nome}`,
            baseEsq,
            baseTop,
        );
        pulaLinha()
        // doc.text( ` -> ${p.quantidade} | ${p.nome}`,
        doc.setFontSize(15);
        if(p.observacao && p.observacao != ''){
            doc.text( `-> ${p.observacao}`,
                baseEsq + 3,
                baseTop,
            );
            pulaLinha()
        }
        
    });

    // doc.internal.pageSize.setHeight()

    // doc.autoTable(columnsItens, pedido.produtos.map(item => {
    //     return {
    //             quantidade: `x${item.quantidade}`,
    //             nome: item.nome,
    //             preco: `R$ ${item.preco * item.quantidade}`
    //            }
    //         }), {
    //             showHeader: "everyPage",
    //             startY: baseTop + 5,
    //             tableWidth: pageWidth - 16,
    //             margin: { left: baseEsq },
    //             theme: "grid",
    //             headerStyles: { 
    //                 fillColor: [240], 
    //                 textColor: 0,
    //                 lineColor: 0,
    //                 fontSize: 15
    //             },
    //             bodyStyles: { lineColor: [30, 30, 30], textColor: 0,},
    //             styles: {
    //                 lineColor: 200,
    //                 startY: baseEsq,
    //                 fontSize: 18
    //             }
        
    // });
    // console.log('tamanho', doc.internal.pageSize.setHeight(280))
    // console.log('autotable', doc.autoTable().getPageHeight())
    
    // baseTop = doc.autoTable.previous.finalY;
    doc.setDrawColor(0, 0, 0);

    // pulaLinha()
    // doc.setLineWidth(1);
    doc.line(baseEsq , baseTop-3, pageWidth - 8, baseTop-3);

    if(pedido.retirarBalcao) {
        pulaLinha()
        doc.setFontSize(16);
        doc.setFont("helvetica", "normal");
        doc.text( `${pedido.observacao}`,
            baseEsq,
            baseTop,
        );
        pulaLinha()
        pulaLinha()
        doc.line(baseEsq , baseTop-3, pageWidth - 8, baseTop-3);
    }

    if(pedido.retirarBalcao) {
        pulaLinha()
        doc.setFontSize(22);
        doc.setFont("helvetica", "bold");
        doc.text( `Retirada balcão!`,
            baseEsq,
            baseTop,
        );
    }
    
    if(pedido.observacao && pedido.observacao != "") {
        pulaLinha()
        doc.setFontSize(20);
        doc.setFont("helvetica", "bold");
        doc.text( `Observação:`,
            baseEsq,
            baseTop,
        );
        doc.setFontSize(15);
        pulaLinha()
        doc.setFont("helvetica", "normal");
        doc.text( `${pedido.observacao}`,
            baseEsq,
            baseTop,
        );
        pulaLinha()
    }
    
    if(pedido.entrega && pedido.entrega > 0) {
        pulaLinha()
        doc.setFontSize(22);
        doc.setFont("helvetica", "bold");
        doc.text( `Entrega:`,
            baseEsq,
            baseTop,
        );
        doc.setFontSize(22);
        doc.setFont("helvetica", "normal");
        doc.text( `R$ ${_this.$options.filters.currency(pedido.entrega, "")}`,
            pageWidth - 8,
            baseTop,
            "right",
        );
    }

    if(pedido.desconto && pedido.desconto > 0){
        pulaLinha()
        pulaLinha()
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        doc.text( `Desconto:`,
            baseEsq,
            baseTop,
        );
        doc.setFontSize(22);
        doc.text( `R$ ${_this.$options.filters.currency(pedido.desconto, "")}`,
            pageWidth - 8,
            baseTop,
            "right",
        );
    }

    pulaLinha()
    pulaLinha()
    doc.setFontSize(22);
    doc.setFont("helvetica", "bold");
    const fPagamentos= {
        dinheiro: "Dinheiro",
        pix: "PIX",
        boleto: "Boleto",
        cartao_debito: "Cartão débito",
        cartao_credito: "Cartão crédito",
        vale_alimentacao: "Vale alimentação",
        vale_refeicao: "Vale refeição"
    }

    
    doc.text( `[${fPagamentos[pedido.formaPagamento]||'Vale refeição'}]`,
        baseEsq,
        baseTop,
    );

    pulaLinha()
    doc.text( `Total:`,
        baseEsq,
        baseTop,
    );
    
    doc.setFontSize(22);
    doc.text( `R$ ${_this.$options.filters.currency(pedido.total, "")}`,
        pageWidth - 8,
        baseTop,
        "right",
    );

    // _this.axios.get('https://brasilapi.com.br/api/cep/v2/89056110').then((res) => {
    //   console.log('feito')
    //   console.log(res)
    // })
    
    // doc.setProperties({
    //   title: `OS ${os.numeroOrdemServico} - ${os.nomeCliente}`,
    //   subject: `Ordem de serviço`,		
    //   author: `${objEmpresa.nome} - ${objEmpresa.cnpj}`,
    //   keywords: `${objEmpresa._id}, ${os.nomeCliente}, ${os.numeroOrdemServico}, ${os.responsavel}`,
    //   creator: 'Griffo Oficinas'
    // });
    

    // doc.save(`pdf_ordem_servico_${os.numeroOrdemServico}_${os.nomeCliente}`)
    window.open(URL.createObjectURL(doc.output("blob")))

    function pulaLinha() {
        baseTop = baseTop + 7;
        return baseTop;
    }
}   
